// @import url('https://fonts.googleapis.com/css2?family=Poppins:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Outfit:wght@100..900&display=swap');

// $font-family-base: 'Poppins';
$font-family-base: 'Mulish';


html,
body {
    font-family: $font-family-base;
}