/* cyrillic-ext */
@font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Pttg83HX_SGhgqk2joiaqRXBA.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Pttg83HX_SGhgqk2joraqRXBA.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Pttg83HX_SGhgqk2jogaqRXBA.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Pttg83HX_SGhgqk2johaqRXBA.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Pttg83HX_SGhgqk2jovaqQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Ptvg83HX_SGhgqk0gotcqA.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Ptvg83HX_SGhgqk2wotcqA.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Ptvg83HX_SGhgqk0AotcqA.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Ptvg83HX_SGhgqk0QotcqA.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 200 1000;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/mulish/v13/1Ptvg83HX_SGhgqk3wot.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/outfit/v11/QGYvz_MVcBeNP4NJuktqQ4E.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/outfit/v11/QGYvz_MVcBeNP4NJtEtq.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
