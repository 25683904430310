/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/font";
// @import "./assets/styles/boostrap_theme_variables";
@import "bootstrap/scss/bootstrap";

@import "./assets/styles/custom";
@import "./assets/css/style.css";

@import "./assets/css/responsive.css";
@import "./assets/css/custom.css";
@import "./assets/css/aos.css";
@import "./assets/css/slick.min.css";

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.btn-defalult {
  border-radius: 6px;
  display: block;
  width: 100%;
  padding: 18px;
  background-color: #32e875;
}
.btn-defalult:hover {
  background-color: #efe6dd;
  color: #014017;
}
.btn-text {
  font-family: Outfit;
  //   font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  color: #014017;
}
.farm-background {
  padding: 10rem 0px 25px !important;
  background-color: #014017;
  padding: 30px;
}
.form-fields {
  padding: 18px !important;
  background: transparent !important;
  border-radius: 6px !important;
  border: solid 1px #658c6f !important;
}
.form-fields:focus {
  border-radius: 6px !important;
  border: solid 1px #32e875 !important;
}
.is-invalid {
  // border-color: $danger !important;
  border-radius: 6px;
  border: solid 1px #fb5737 !important;
}

.is-invalid::placeholder {
  color: $danger !important;
}
.fv-plugins-message-container .fv-error-block {
  color: $danger !important;
}
.mydiv {
  max-width: 433px;
  margin: auto;
}

.Create-Account {
  font-family: Outfit;
  font-size: 50px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #efe6dd;
}
// @media (max-width: 767px) {
//   .Create-Account {
//     font-family: Outfit;
//     font-size: 25px;
//     font-weight: 600;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.1;
//     letter-spacing: normal;
//     text-align: center;
//     color: #efe6dd;
//   }
// }
.Already-have-an-acco {
  font-family: Mulish;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #efe6dd;
}
.Already-have-an-acco .sign-in {
  font-weight: bold;
  color: #32e875;
  text-decoration: underline;
}
::placeholder {
  color: #efe6dd;
}

.firstname-margin-top {
  margin-top: 40px;
}
input {
  background-color: #014017;
  color: #efe6dd !important;
}

// .form-mobile-padding{

// }
@media (max-width: 767px) {
  .form-mobile-padding {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.content-size {
  max-width: 1320px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  gap: 130px;
}
@media (max-width: 768px) {
  .content-size {
    // max-width: 1320px;
    // margin: auto;
    padding-left: 0px;
    padding-right: 0px;
    gap: 0px;
  }
}
.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 56px;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .marquee {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
    height: 16px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 3s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30%);
  }
}
.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 56px;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .marquee {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
    height: 16px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 3s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30%);
  }
}
.A-Healthier-You-A-H {
  font-size: 28px;
  font-weight: 900;
  color: #014017;
}
.healthierRectangle {
  background-color: #32e875;
  margin-top: 150px;
}
@media (max-width: 768px) {
  .healthierRectangle {
    background-color: #32e875;
    margin-top: 35px;
  }
}

.My-Account {
  font-size: 50px;
  font-weight: 900;
  line-height: 1.08;
  color: #efe6dd;
}
@media (max-width: 768px) {
  .My-Account {
    font-family: Outfit;
    font-size: 22px;
    font-weight: 900;
    line-height: 1.09;
    color: #efe6dd;
  }
}

.phoneText {
  font-size: 18px;
  line-height: 1.33;
  text-align: right;
  color: #efe6dd;
}

@media (max-width: 768px) {
  .phoneText {
    font-family: Outfit;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    color: #a8bfaa;
  }
}

.modal-confirm-size {
  border-radius: 8px;
  // box-shadow: 0 11px 25px -6px rgba(0, 0, 0, 0.5);
}
// .modal-confirm-size-user {
//   max-width: 840px;
// }
