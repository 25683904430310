.teamText {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin: 0px 0px 80px 0px;
}

.section-width {
    max-width: 1320px;
}

@media (max-width: 768px) {
    .teamText {
        max-width: 350px;
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        line-height: 1.09;
        margin: auto;
        letter-spacing: normal;
        margin-bottom: 22px;
    }
}

.backImg {
    background-size: contain;
    background-color: #014017 !important;
}

.backgImg {
    background-image: none !important;
    background-color: #014017 !important;
}

.teamImg {
    /* // max-width: 1320px; */
}

.section-text {
    font-size: 24px;
    max-width: 1019px;
    margin: auto;
}

@media (max-width: 768px) {
    .teamImg {
        width: 311px;
        height: 242px;
        border-radius: 28px;
    }
}

.Message-From-Our-Fou {
    color: white;
    font-size: 36px;
    font-weight: 900;
}

@media (max-width: 768px) {
    .Message-From-Our-Fou {
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        color: #efe6dd;
    }
}

.Message-From-Our-Fou .ourfound {
    color: #32e875;
}

@media (max-width: 768px) {
    .Message-From-Our-Fou .ourfound {
        color: #efe6dd;
    }
}

.Facing-a-future-wher {
    text-align: left;
    max-width: 673px;

    font-size: 24px;
    font-weight: 600;
    color: #efe6dd;
}

@media (max-width: 768px) {
    .Facing-a-future-wher {
        text-align: start;
        padding-top: 16px;
        padding-left: 11px;
        font-size: 14px;
        font-weight: 600;
        color: #efe6dd;
    }
}

.messg-css {
    margin-left: auto;
}

.content-size {
    max-width: 1320px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
    gap: 130px;
}

@media (max-width: 768px) {
    /* .content-size {
        // max-width: 1320px;
        // margin: auto;
        padding-left: 0px;
        padding-right: 0px;
        gap: 0px;
    } */
}

.To-your-health-and-t {
    text-align: left;
    margin-top: 11px;
    font-size: 16px;
    font-weight: 600;
    color: #a8bfaa;
}

@media (max-width: 768px) {
    .To-your-health-and-t {
        margin-top: 0px;
        padding-left: 11px;
        text-align: left;
        font-weight: 600;
        color: #a8bfaa;
    }
}

.img-impact-4 {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    /* .img-impact-4 {
        // max-width: 390px;
    } */
}

.img-impact-6 {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .img-impact-6 {
        display: none;
    }
}

.To-your-health-and-t .text-style-1 {
    font-family: PristineScript;
    font-size: 52px;
    color: #32e875;
}

@media (max-width: 768px) {
    .To-your-health-and-t .text-style-1 {
        font-family: PristineScript;
        font-size: 41.6px;
        color: #32e875;
    }
}

.visionImg {
    width: 100%;
}

@media (max-width: 768px) {
    /* .visionImg {
        // width: 343px;
        // height: 288px;
        width: 100%;
    } */
}

.visison-img {}

@media (max-width: 768px) {
    /* .visison-img {
        // width: 343px;
        // height: 288px;
        width: 100%;
    } */
}

.vision-text {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: normal;
}

@media (max-width: 768px) {
    .vision-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
    }
}

.missionText {
    color: #efe6dd;
}

.Sustainability-inno {
    font-size: 34px;
    font-weight: 900;
    color: #32e875;
}

@media (max-width: 768px) {
    .Sustainability-inno {
        max-width: 339px;
        margin: auto;
        font-size: 16px;
        font-weight: 900;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #32e875;
    }
}

.A-Healthier-You-A-H {
    font-size: 28px;
    font-weight: 900;
    color: #014017;
}

.healthierRectangle {
    background-color: #32e875;
    margin-top: 150px;
}

@media (max-width: 768px) {
    .healthierRectangle {
        background-color: #32e875;
        margin-top: 35px;
    }
}

@media (max-width: 768px) {
    .Sustainability-inno-mobile {
        margin-top: 0px;
    }
}

.offering {
    font-size: 34px;
    font-weight: 900;
    color: #014017;
}

@media (max-width: 768px) {
    .offering {
        font-size: 16px;
        font-weight: 900;
        color: #014017;
    }
}

.Completely-soilless {
    max-width: 530px;
    padding-left: 60px;
    margin-top: 50px;
    font-size: 24px;
    line-height: normal;
    letter-spacing: normal;
    color: #014017;
}

@media (max-width: 768px) {
    .Completely-soilless {
        font-family: Outfit;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #014017;
    }
}

@media (max-width: 768px) {
    .difference {
        margin-top: 0%;
    }
}

.visionFlex {
    display: block;
}

@media (max-width: 768px) {
    .visionFlex {
        display: flex;
        flex-direction: column-reverse;
    }
}

@media (max-width: 768px) {
    .missionText {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        color: #efe6dd;
    }
}

.missionPara {
    margin-top: 0px;
}

@media (max-width: 768px) {
    .missionPara {
        margin-top: 20px;
    }
}

.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 56px;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .marquee {
        position: relative;
        max-width: 100%;
        overflow-x: hidden;
        height: 16px;
        font-family: Outfit;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
    }
}

.track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 3s linear infinite;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-30%);
    }
}

.mesage-bootom {
    padding: 15rem 0 15.375rem;
}

@media (max-width: 768px) {
    .mesage-bootom {
        padding: 7rem 0 10.375rem;
    }
}

.vision-top {
    margin-top: 81px;
}

@media (max-width: 768px) {
    .vision-top {
        margin-top: 0px;
    }
}

.make-diiference {
    margin: auto;
    /* // gap: 25px; */
}

.founder-container {
    margin-top: 98px;
}

.d-block-mission {
    /* // width: 343px; */

    margin: 23px 16px 24px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #efe6dd;
}

@media (max-width: 767px) {
    .about-mobile {
        padding: 5rem 0 5rem;
    }
}

@media (max-width: 767px) {
    .mission-mobile-bottom {
        margin-bottom: 0%;
    }
}

.team-name-color {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #60267f;
}

.team-position-name {
    font-family: Mulish;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #014017;
}
