@media (min-width: 1199px) {
  .partner-img {
    width: 20%;
  }
}
@media (min-width: 1600px) {
  .container {
    /* max-width: 1520px; */
    max-width: 95rem;
  }
}
@media (max-width: 1400px) {
  header .navbar-nav {
    /* gap: 40px; */
    gap: 2.5rem;
  }
  .nav-cart {
    /* gap: 26px; */
    gap: 1.625rem;
  }
  /* .hero {
    padding: 220px 0 400px;
  } */
  .hero h1 {
    /* font-size: 100px; */
    font-size: 6.25rem;
  }
}
@media (max-width: 1199px) {
  /* .hero {
    padding: 212px 0 266px;
  } */
  .hero h1 {
    /* font-size: 90px; */
    font-size: 5.625rem;
  }
  .section-title {
    /* font-size: 36px; */
    font-size: 2.25rem;
  }
  .farming-counter-wrapper {
    /* margin-top: 50px; */
    margin-top: 3.125rem;
  }
  .counter-card {
    /* padding: 18px 30px 6px 14px; */
    padding: 1.125rem 1.875rem 0.375rem 0.875rem;
    /* gap: 30px; */
    gap: 1.875rem;
  }
  .counter-card p {
    /* font-size: 22px; */
    font-size: 1.375rem;
  }
  .counter-card h3 {
    /* font-size: 60px; */
    font-size: 3.75rem;
  }
  .benefits {
    /* padding: 100px 0 0; */
    padding: 6.25rem 0 0;
  }
  .mid-img {
    /* margin-bottom: -115px; */
    margin-bottom: -7.1875rem;
  }
  .about {
    /* padding: 200px 0 130px; */
    padding: 12.5rem 0 8.125rem;
  }
  .grow-more,
  .subscribe,
  .partner,
  footer {
    /* padding: 100px 0; */
    padding: 6.25rem 0;
  }
  .review {
    /* padding: 50px 0 100px; */
    padding: 3.125rem 0 6.25rem;
  }
  .widget-links a,
  .footer-follow p,
  .footer-follow h5 {
    /* font-size: 18px; */
    font-size: 1.125rem;
  }
  .widget-links {
    /* gap: 20px; */
    gap: 1.25rem;
  }
  /* 05/04/2024 */
  .hero-abp {
    /* padding: 160px 0 150px; */
    padding: 10rem 0 9.375rem;
  }
  .hero-abp p {
    /* font-size: 18px; */
    font-size: 1.125rem;
  }
  .hero-img-abp {
    /* margin: 40px 0 0; */
    margin: 2.5rem 0 0;
  }
  .about-fogponics,
  .faq {
    /* padding: 110px 0; */
    padding: 6.875rem 0;
  }
  .impact-card h4 {
    /* font-size: 32px; */
    font-size: 2rem;
  }
  .impact-lg-card h4 {
    /* font-size: 40px; */
    font-size: 2.5rem;
  }
  .impact-lg-card {
    /* min-height: 400px; */
    min-height: 25rem;
  }
  .about-content {
    /* padding-top: 110px; */
    padding-top: 6.875rem;
  }
  .climate {
    /* padding: 160px 0 100px; */
    padding: 10rem 0 6.25rem;
  }
  .about-content .mid-img {
    /* margin: 0 auto -80px; */
    margin: 0 auto -5rem;
  }
  .fwt-cart-product-gms select{
    width: 16.25rem;
  }
}
@media (max-width: 992px) {
  header .navbar-nav {
    /* gap: 10px; */
    gap: 0.625rem;
    /* max-width: 720px; */
    max-width: 45rem;
    margin: 0 auto;
    /* padding-inline: 12px; */
    padding-inline: 0.75rem;
  }
  .nav-right {
    background: #014017;
    width: 100%;
    left: 0;
    top: 100%;
    position: absolute;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .navbar-nav {
    /* padding: 20px; */
    padding: 1.25rem;
  }

  body.open-menu {
    overflow: hidden;
  }
  body.open-menu #main-content:before {
    background: #3c3c3c;
    content: "";
    inset: 0;
    opacity: 40%;
    position: fixed;
    transition: 0.3s;
    z-index: 99;
  }

  .navbar-nav li .sub-menu {
    position: relative;
    border: none;
    box-shadow: none;
  }

  header .navbar-nav li ~ li {
    /* margin-top: 10px; */
    margin-top: 0.625rem;
  }

  .sub-menu.dropdown-menu.show {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  .nav-right.show {
    height: fit-content;
    z-index: 999;
  }
  .nav-cart {
    /* gap: 20px; */
    gap: 1.25rem;
  }
  /*  */
  .hero h1 {
    /* font-size: 70px; */
    font-size: 4.375rem;
  }
  .hero p {
    /* font-size: 35px; */
    font-size: 2.1875rem;
  }
  .section-title {
    /* font-size: 32px; */
    font-size: 2rem;
  }
  .counter-card h3 {
    /* font-size: 45px; */
    font-size: 2.8125rem;
  }
  .counter-card p {
    /* font-size: 17px; */
    font-size: 1.0625rem;
  }
  .section-desc {
    /* font-size: 16px; */
    font-size: 1rem;
  }
  .mid-img {
    /* margin-bottom: -85px; */
    margin-bottom: -5.3125rem;
  }
  .about {
    /* padding: 150px 0 130px; */
    padding: 9.375rem 0 8.125rem;
  }
  .grow-more,
  .subscribe,
  .partner,
  .blog,
  footer {
    /* padding: 80px 0; */
    padding: 5rem 0;
  }
  /* 05/04/2024 */
  .about.about-fogponics {
    /* padding: 110px 0; */
    padding: 6.875rem 0;
  }
  .faq-left h2 {
    /* font-size: 28px; */
    font-size: 1.75rem;
    /* margin-bottom: 30px; */
    margin-bottom: 1.875rem;
  }
  .accordion-button:not(.collapsed),
  .accordion-button {
    /* font-size: 22px; */
    font-size: 1.375rem;
  }

  .climate-content {
    max-width: max-content;
  }
  .faq-wrapper {
    /* padding: 40px; */
    padding: 2.5rem;
  }
  .about-content .mid-img {
    /* margin: 0 auto -59px; */
    margin: 0 auto -3.6875rem;
  }
  .about-content p {
    /* margin-bottom: -70px; */
    margin-bottom: -4.375rem;
  }
  .impact-card {
    margin: 0px;
  }
  .grow-card {
    margin: 0 0;
  }
  .accordion-item:last-of-type {
    border-bottom: none;
  }
  .faq {
    height: 65rem;
  }
}
@media (max-width: 767px) {
  header .navbar-nav {
    /* max-width: 540px; */
    max-width: 33.75rem;
  }
  .subscribe-card {
    /* padding: 20px; */
    padding: 1.25rem;
  }
  .vertical-farming {
    /* padding: 80px 0; */
    padding: 5rem 0;
    background-color: #014017;
  }
  .counter-card {
    /* padding: 14px 30px 14px 14px; */
    padding: 0.875rem 1.875rem 0.875rem 0.875rem;
    /* gap: 12px; */
    gap: 0.75rem;
    /* border-radius: 0 25px 25px 0; */
    border-radius: 0 1.5625rem 1.5625rem 0;
    transform: scaleX(0) scaleY(1);
    height: auto;
    /* min-height: 135px; */
    min-height: 8.4375rem;
    transform-origin: left;
    flex-direction: column-reverse;
  }
  .counter-card-2 {
    animation-delay: 1s;
  }
  .counter-card-3 {
    animation-delay: 2s;
  }
  .counter-card-4 {
    animation-delay: 3s;
  }
  .counter-card p {
    /* font-size: 18px; */
    font-size: 1.125rem;
  }
  @keyframes vertical-scale {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
  .benefits {
    /* padding: 70px 0 0; */
    padding: 4.375rem 0 0;
  }
  .mid-img {
    /* margin-bottom: -63px; */
    margin-bottom: -3.9375rem;
  }
  .benefits .title-group {
    /* margin-bottom: 40px; */
    margin-bottom: 2.5rem;
  }
  .about {
    /* padding: 128px 0 180px; */
    padding: 8rem 0 11.25rem;
  }
  .widget-links a,
  .footer-follow p,
  .footer-follow h5 {
    /* font-size: 16px; */
    font-size: 1rem;
  }
  .widget-links a {
    color: #fff;
  }
  .widget-links {
    /* gap: 10px; */
    gap: 0.625rem;
  }
  .slider-slide .slick-next {
    /* left: 56px; */
    left: 3.5rem;
  }
  .slider-slide .slick-prev,
  .slider-slide .slick-next {
    /* width: 40px;
    height: 40px; */
    width: 2.5rem;
    height: 2.5rem;
    background-position: center;
    /* background-size: 40px; */
    background-size: 2.5rem;
  }
  .hero p {
    /* font-size: 28px; */
    font-size: 1.75rem;
  }
  .water-conservation-card h4 span {
    /* font-size: 44px; */
    font-size: 2.75rem;
  }
  .water-conservation-card h4 {
    /* font-size: 24px; */
    font-size: 1.5rem;
  }
  /* 05/04/2024 */
  .hero-abp {
    /* padding: 100px 0 150px; */
    padding: 6.25rem 0 9.375rem;
    line-height: unset;
  }
  .hero-abp p {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .hero-img-abp {
    /* margin: 24px 0 0; */
    margin: 1.5rem 0 0;
    /* height: 240px; */
    height: 15rem;
  }
  .about-fogponics {
    /* padding: 56px 0 81px; */
    padding: 3.5rem 0 5.0625rem;
    text-align: center;
  }
  .about-fogponics .section-desc {
    color: #000;
  }
  .faq {
    /* padding: 80px 0; */
    padding: 5rem 0;
    height: 60rem;
  }
  .faq-wrapper {
    /* padding: 12px; */
    padding: 0.75rem;
    /* border-radius: 20px; */
    border-radius: 1.25rem;
  }
  .accordion-button:not(.collapsed),
  .accordion-button {
    /* font-size: 16px; */
    font-size: 1rem;
  }
  .accordion-body {
    /* padding: 0 16px 16px; */
    padding: 0 1rem 1rem;
  }
  .accordion-body p {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .faq-left h2 {
    /* font-size: 20px; */
    font-size: 1.25rem;
    /* margin-bottom: 16px; */
    margin-bottom: 1rem;
  }
  .our-impact {
    /* padding: 40px 0 64px; */
    padding: 2.5rem 0 4rem;
  }
  .about-content {
    /* padding-top: 40px; */
    padding-top: 2.5rem;
  }
  .impact-card {
    /* min-height: 250px; */
    min-height: 15.625rem;
    /* border-radius: 20px; */
    border-radius: 1.25rem;
  }
  .impact-content {
    /* padding: 16px 20px; */
    padding: 1rem 1.25rem;
  }
  .about-content p,
  .impact-card p {
    /* font-size: 16px; */
    font-size: 1rem;
  }
  .impact-card h4 {
    /* font-size: 22px; */
    font-size: 1.375rem;
  }
  .impact-lg-card {
    /* min-height: 340px; */
    min-height: 21.25rem;
  }
  .impact-lg-card h4 {
    /* font-size: 26px; */
    font-size: 1.625rem;
  }
  .climate {
    /* padding: 110px 0 80px; */
    padding: 6.875rem 0 5rem;
  }
  .about-content .mid-img {
    /* margin: 0 auto -44px; */
    margin: 0 auto -2.75rem;
  }
  .about-content .mid-img {
    transform: scale(1.06);
  }
  
  .fwt-cart-product-gms .qty-input{
    max-width: 4.688rem;
  }
  .cart-card img{
    max-width: 6.25rem;
    height: 6.25rem;
  }
  .cart-remove-btn{
    display: none;
  }
  .remove-cart-set{
    display: block;
  }
  .paymet-text p{
    text-align: center;
    margin-top: 2rem;
  }
  .payment-logo img {
    width: 4.225rem;
  }
  .payment-logo{
    justify-content: center;
  }
  .all-product-select-type{
    max-width: 100%;
    margin-top: 1rem;
  }
  .fwt-shop-img img{
    max-width: 11.25rem;
    height: 6.125rem;
    padding: 0.625rem;
    object-fit: contain;
  }
  .products-img a {
    font-size: 0.825rem;
  }
  .all-fwt-product-card .product-card-img img{
    height: 100%;
  }
  /*  */
  .fwt-shop-img > .row{
    padding: 0 0.313rem;
  }
  .fwt-product-card-cart > .row {
    padding: 0 0.313rem;
  }
}
@media (max-width: 576px) {
  header .navbar {
    /* padding: 18px 0; */
    padding: 1.125rem 0;
  }
  .stickyHeader .navbar {
    /* padding: 14px 0; */
    padding: 0.875rem 0;
  }
  .toggle-menu-icon {
    /* width: 20px; */
    width: 1.25rem;
    /* height: 16px; */
    height: 1rem;
  }
  .toggle-menu-icon span {
    height: 2px;
  }
  .toggle-menu-icon span:nth-child(2),
  .toggle-menu-icon span:nth-child(3) {
    /* top: 9px; */
    top: 0.5625rem;
  }
  .toggle-menu-icon span:nth-child(4) {
    /* top: 16px; */
    top: 1rem;
  }
  .nav-right {
    width: 100%;
  }
  .nav-cart {
    /* gap: 10px; */
    gap: 0.625rem;
  }
  .cart-btn img {
    /* height: 20px; */
    height: 1.25rem;
  }
  /*  */
  .primary-btn {
    /* font-size: 14px; */
    font-size: 0.875rem;
    letter-spacing: 0.7px;
    /* padding: 10px 25px; */
    padding: 0.625rem 1.5625rem;
  }
  .section-title {
    /* font-size: 22px; */
    font-size: 1.375rem;
    line-height: 1.09;
    /* margin-bottom: 10px; */
    margin-bottom: 0.625rem;
  }
  .section-desc {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .hero h1 {
    /* font-size: 64px; */
    font-size: 4rem;
    line-height: 0.89;
    /* margin-bottom: 16px; */
    margin-bottom: 1rem;
  }
  .hero p {
    /* font-size: 22px; */
    font-size: 1.375rem;
  }
  .mid-img {
    /* margin-top: 30px; */
    margin-top: 1.875rem;
    /* margin-bottom: -51px; */
    margin-bottom: -3.1875rem;
    /* width: 417px; */
    width: 26.0625rem;
    margin-inline: auto;
  }
  .benefit-card img {
    /* height: 50px; */
    height: 3.125rem;
  }
  .benefit-card h5 {
    /* margin-top: 10px; */
    margin-top: 0.625rem;
    /* font-size: 16px; */
    font-size: 1rem;
  }
  .title-group {
    /* margin-bottom: 30px; */
    margin-bottom: 1.875rem;
  }
  .grow-more {
    /* padding: 80px 0 40px; */
    padding: 5rem 0 2.5rem;
  }
  .subscribe {
    /* padding: 40px 0 52px; */
    padding: 2.5rem 0 3.25rem;
  }
  .blog {
    /* padding: 52px 0 40px; */
    /* padding: 52px 0 40px; */
    padding: 3.25rem 0 2.5rem;
  }
  .review {
    /* padding: 40px 0 80px; */
    padding: 2.5rem 0 5rem;
  }
  .grow-card p {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .grow-card h4 {
    /* margin: 0 0 10px; */
    margin: 0 0 0.625rem;
  }
  .subscribe-card-right form {
    /* margin: 16px 0; */
    margin: 1rem 0;
    max-width: 16.3125rem;
  }
  .blog-card {
    /* padding: 20px 17px 17px 17px; */
    padding: 1.25rem 1.0625rem 1.0625rem 1.0625rem;
  }
  .slider-slide {
    /* padding-bottom: 62px; */
    padding-bottom: 3.875rem;
  }
  .slider-slide p {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .grow-card .blog-img,
  .subscribe-card,
  .subscribe-card-left {
    /* border-radius: 24px; */
    border-radius: 1.5rem;
  }
  .grow-card {
    /* padding: 20px 20px 30px 20px; */
    padding: 1.25rem 1.25rem 1.875rem 1.25rem;
  }
  .water-conservation-card,
  .year-round-card {
    /* padding-bottom: 170px; */
    padding-bottom: 10.625rem;
  }
  .footer-bottom p {
    /* font-size: 16px; */
    font-size: 1rem;
  }
  footer {
    /* padding: 51px 0 47px; */
    padding: 3.1875rem 0 2.9375rem;
  }
  /* 05/04/2024 */
  .about-fogponics h4 {
    /* font-size: 14px; */
    font-size: 0.875rem;
    margin: 2.3125rem 2rem;
  }
  .impact-card {
    /* min-height: 200px; */
    min-height: 12.5rem;
    /* border-radius: 20px; */
    border-radius: 1.25rem;
  }
  .impact-content {
    /* padding: 16px 20px; */
    padding: 1rem 1.25rem;
  }
  .about-content p,
  .impact-card p {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .impact-card h4 {
    /* font-size: 18px; */
    font-size: 1.125rem;
  }
  .impact-lg-card {
    /* min-height: 260px; */
    min-height: 16.25rem;
  }
  .impact-lg-card h4 {
    /* font-size: 20px; */
    font-size: 1.25rem;
  }
  .about-content .mid-img {
    /* margin: 0 auto -35px; */
    margin: 0 auto -2.1875rem;
  }
  .about-content p {
    /* margin-bottom: -50px; */
    margin-bottom: -3.125rem;
  }
  /* 25/05/2024 */
  .teamText{
    max-width: 100% !important;
  }
  .teamImg{
    width: 100% !important;
    height: auto;
  }
  .content-size{
    padding-inline: 0;
  }
  .d-block-mission{
    margin: 23px 0 24px !important;
  }
  .Completely-soilless{
    padding-left: 0 !important;
  }
  
  .fwt-cart-section{
    padding-block: 7rem 0;
  }
  .fwt-cart-footer{
    margin-top: 1.875rem;
  }
  .fwt-cart-product-gms select{
    width: 10.5rem;
  }
  .cart-card h6{
    font-size: 1.125rem;
  }
  .cart-card p{
    font-size: 1rem;
  }
  .fwt-cart-card p{
    font-size: 0.875rem;
  }
  .fwt-cart-card h6{
    font-size: 1rem;
  }
  .fwt-cart-product-gms select{
    margin-right: 0;
  }
  .payment-logo{
    justify-content: center;
  }
  .payment-logo img{
    width: 3.225rem;
  }
  .payment-logo {
    gap: 0.75rem;
  }
  .all-products-card select {
    padding: 0.813rem 0.75rem;
  }
  .product-card-text h6{
    font-size: 1rem;
  }
  .product-card-text p{
    font-size: 1rem;
  }
  .fwt-product-card select{
    font-size: 1rem;
  }
  .fwt-product-card .qty-input{
    width: 4.25rem;
  }
  .add-btn{
    width: 4.25rem;
  }
  .all-fwt-product-card .product-card-text{
    padding: 0.563rem !important;
    border-radius: 0.75rem;
  }
  .all-fwt-product-card .product-card-img img{
    padding: 10px;
  }
  .fwt-shop-section{
    padding-block: 6.25rem 5.625rem;
  }
  .all-products-card{
    margin-top: 1.5rem;
  }
  .fwt-product-card-cart{
    margin-top: 1rem;
  }
  .products-img a {
    padding-top: 0.5rem;
  }
}
@media (max-width: 575px) {
  input[required]+label{
    left: 2.813rem;
  }  
}
@media (max-width: 440px) {
  .mid-img {
    /* margin-bottom: -37px; */
    margin-bottom: -2.3125rem;
    /* width: 296px; */
    width: 18.5rem;
    margin-inline: auto;
  }
  /* 05/04/2024 */
  .about-content .mid-img {
    /* margin: 0 auto -25px; */
    margin: 0 auto -1.5625rem;
  }
  .climate-content p {
    /* font-size: 14px; */
    font-size: 0.875rem;
  }
  .climate {
    /* padding: 80px 0 50px; */
    padding: 5rem 0 3.125rem;
  }
  .about-content p {
    /* margin-bottom: -30px; */
    margin-bottom: -1.875rem;
  }
  .fwt-cart-product-gms select{
    max-width: 10.5rem;
    width: 100%;
  }
  .payment-logo {
    gap: 0.5rem;
  }
  .fwt-shop-img img {
    max-width: 5.25rem;
    height: 3.875rem;
    padding: 0.25rem;
    border-radius: 0.563rem;
    width: 100%;
  }
  .product-card-text h6{
    font-size: 0.75rem;
    line-height: 0.90rem;
    padding-top: 0;
  }
  .fwt-product-card select{
    font-size: 0.625rem;
    margin-block: 0.25rem 0.375rem;
  }
  .product-card-text p{
    font-size: 0.625rem;
  }
  .add-btn{
    font-size: 0.625rem;
    width: 3rem;
    height: 1.725rem;
  }
  .fwt-product-card .qty-input{
    padding: 0.375rem;
    height: 1.744rem;
  }
  .fwt-product-card .qty-input .product-qty{
    font-size: 0.625rem;
  }
  .fwt-product-card .product-qty, .qty-count{
    height: 1.744rem;
  }
  .products-img a {
    font-size: 0.75rem;
  }
}
@media (min-width: 576px) {
  .container-lg {
    /* max-width: 540px; */
    max-width: 33.75rem;
  }
}
@media (min-width: 768px) {
  .container-lg {
    /* max-width: 720px; */
    max-width: 45rem;
  }
}
@media (min-width: 992px) {
  .container-lg {
    /* max-width: 960px; */
    max-width: 60rem;
  }
  .hero-abp p {
    /* line-height: 24px; */
    line-height: 1.5rem;
  }
  .px-lg-25 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}
@media (min-width: 1200px) {
  .container-lg {
    /* max-width: 1140px; */
    max-width: 71.25rem;
  }
  .hero-abp p {
    /* line-height: 30px; */
    line-height: 1.875rem;
  }
  .hero-abp {
    background-size: contain;
    background-color: #0e4117;
  }
  .faq {
    background-size: contain;
    height: 53rem;
    background-color: #0e4117;
  }
}

@media (min-width: 1400px) {
  .container-lg {
    /* max-width: 1320px; */
    max-width: 82.5rem;
  }
}
